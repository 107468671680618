import React from 'react';
import { connect } from 'react-redux';

const Header = ({ logoutAction, type, typeToggle, loading, name, dsp, ssp }) => {
  let sspToggle = ssp.length ? 'SSP' : '';
  let dspToggle = dsp.length ? 'DSP' : '';

  if (dspToggle && !sspToggle && type !== 'dsp') {
    typeToggle('dsp');
  }

  return (
    <header>
      <span className='company-name'>{name}</span>
      <div className='company-type-toggles'>
        <input
          type='radio'
          checked={type.includes('ssp')}
          id='ssp'
          name='company-type'
          onChange={e => typeToggle(e.target.id, loading)}
        />
        <label htmlFor='ssp'>{sspToggle}</label>
        <input
          type='radio'
          checked={type.includes('dsp')}
          id='dsp'
          name='company-type'
          onChange={e => typeToggle(e.target.id, loading)}
        />
        <label htmlFor='dsp'>{dspToggle}</label>
      </div>
      <span className='logout' onClick={logoutAction}>
        Log out
      </span>
    </header>
  );
};

const mapStateToProps = state => ({
  type: state.type,
  loading: state.loading,
  name: state.companyName,
  dsp: state.dsp,
  ssp: state.ssp,
});

const mapDispatchToProps = dispatch => ({
  logoutAction: () => dispatch({ type: 'LOGOUT' }),
  typeToggle: (payload, loading) => {
    if (loading) {
      return;
    }
    dispatch({ type: 'TYPE_TOGGLE', payload });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
