import React from 'react';
import download from 'js-file-download';
import parser from '../parser';
import Copy from './CopyToClipBoard';

export default ({
  title,
  children,
  data,
  buttonText,
  copyText,
  PeriodSelector = null,
  minHeight = 398,
}) => {
  let copyButton = null;
  if (title === 'Financial chart') {
    copyButton = <Copy text={copyText} />;
  }

  return (
    <div className='card' style={{ minHeight }}>
      <div className='card-header'>
        <span>{title}</span>
        {copyButton}
        <div
          className='button-card'
          onClick={() => download(parser(data), `${title}.csv`)}
        >
          {buttonText || 'Download full report'}
        </div>
      </div>
      {PeriodSelector}
      <div className='card-content'>{children}</div>
    </div>
  );
};
