import React, { Component } from 'react';
import Select from 'react-select';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { connect } from 'react-redux';
import request from '../request';
import { getDailyPeriodData } from '../reducer';

class Tools extends Component {
  constructor() {
    super();
    this.getReport = this.getReport.bind(this);
    this.getUrlParams = this.getUrlParams.bind(this);
  }

  componentDidMount() {
    this.getReport();
    this.props.getDaily(this.props.performancePeriod);
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.getReport();
      this.props.getDaily(this.props.performancePeriod);
    }
  }

  getFormatDate(str) {
    return str.toISOString().slice(0, 10);
  }

  getUrlParams() {
    let dateFrom = this.props.dateFrom.toISOString().slice(0, 10);
    let dateTo = this.props.dateTo.toISOString().slice(0, 10);
    let hour = dateFrom === dateTo ? 1 : 0;
    let params = `?from=${dateFrom}&to=${dateTo}&hour=${hour}`;
    return params;
  }

  getUrlParamsEndpoint_id(params, isFinancial = false) {
    let newParams = params;
    let endpoint = this.props.endpoint;
    if (isFinancial && endpoint) {
      newParams += `&endpoint_id=${endpoint.id}`;
    } else if (isFinancial) {
      return params;
    } else if (endpoint) {
      newParams += `&endpoint_id=${endpoint.id}`;
    } else if (!endpoint && this.props.type === 'ssp') {
      const arrId = this.props.ssp.map(sp => sp.id).join(',');
      newParams += `&endpoint_id=${arrId}`;
    } else if (!endpoint && this.props.type === 'dsp') {
      const arrId = this.props.dsp.map(sp => sp.id).join(',');
      newParams += `&endpoint_id=${arrId}`;
    }
    return newParams;
  }

  getReport() {
    const {
      type,
      fetchFinancialData,
      fetchCounriesData,
      fetchOSData,
      fetchPlatformsData,
      fetchNetworkData,
      startLoad,
      setFinancialUrl,
    } = this.props;

    const params = this.getUrlParams();
    const financialUrl = `${type}/financial${this.getUrlParamsEndpoint_id(params, true)}`;

    setFinancialUrl(financialUrl);
    startLoad();

    request('get', financialUrl, null, fetchFinancialData);

    request(
      'get',
      `${type}/detailed/geo${this.getUrlParamsEndpoint_id(params)}`,
      null,
      fetchCounriesData,
    );

    request(
      'get',
      `${type}/detailed/os${this.getUrlParamsEndpoint_id(params)}`,
      null,
      fetchOSData,
    );

    request(
      'get',
      `${type}/platform${this.getUrlParamsEndpoint_id(params)}`,
      null,
      fetchPlatformsData,
    );

    request(
      'get',
      `dsp/detailed/network${this.getUrlParamsEndpoint_id(params)}`,
      null,
      fetchNetworkData,
    );
  }

  render() {
    const {
      endpoint,
      type,
      dateFrom,
      dateTo,
      changeEndpoint,
      changeDateFrom,
      changeDateTo,
      setToday,
      setYesterday,
      setMonth,
    } = this.props;

    const options = this.props[type];

    return (
      <div className='tools'>
        <div className='tool-endpoint'>
          <p>
            Specific report or&nbsp;
            <span className='text-action' onClick={() => changeEndpoint(null)}>
              company summary
            </span>
            .
          </p>
          <Select
            options={options}
            value={endpoint}
            getOptionLabel={data => `#{${data.id}}`}
            getOptionValue={data => data.id}
            onChange={changeEndpoint}
            placeholder='Select endpoint'
          />
        </div>
        <div className='tool-daterange'>
          <p>
            Date range.&nbsp;
            <span onClick={setToday} className='text-action'>
              Today
            </span>
            ,&nbsp;
            <span onClick={setYesterday} className='text-action'>
              yesterday
            </span>
            ,&nbsp;
            <span onClick={setMonth} className='text-action'>
              current month
            </span>
            .
          </p>
          <DayPickerInput
            formatDate={this.getFormatDate}
            inputProps={{ readOnly: true }}
            onDayChange={changeDateFrom}
            value={dateFrom}
          />
          <DayPickerInput
            formatDate={this.getFormatDate}
            inputProps={{ readOnly: true }}
            onDayChange={changeDateTo}
            value={dateTo}
          />
        </div>
        <div onClick={this.getReport} className='button-report'>
          Update reports
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  endpoint,
  dsp,
  ssp,
  type,
  dateFrom,
  dateTo,
  performancePeriod,
}) => ({
  endpoint,
  dsp,
  ssp,
  type,
  dateFrom,
  dateTo,
  performancePeriod,
});

const mapDispatchToProps = dispatch => ({
  changeEndpoint: payload => dispatch({ type: 'CHANGE_ENDPOINT', payload }),
  changeDateFrom: payload => dispatch({ type: 'CHANGE_DATE_FROM', payload }),
  changeDateTo: payload => dispatch({ type: 'CHANGE_DATE_TO', payload }),
  fetchFinancialData: payload => dispatch({ type: 'FETCH_FINANCE', payload }),
  fetchCounriesData: payload => dispatch({ type: 'FETCH_COUNTRIES', payload }),
  fetchOSData: payload => dispatch({ type: 'FETCH_OS', payload }),
  fetchPlatformsData: payload => dispatch({ type: 'FETCH_PLATFORMS', payload }),
  fetchNetworkData: payload => dispatch({ type: 'FETCH_NETWORK', payload }),
  startLoad: () => dispatch({ type: 'FETCHING_START' }),
  setToday: () => dispatch({ type: 'DATE_TODAY' }),
  setYesterday: () => dispatch({ type: 'DATE_YESTERDAY' }),
  setMonth: () => dispatch({ type: 'DATE_MONTH' }),
  setFinancialUrl: payload => dispatch({ type: 'SET_FINANCIAL_URL', payload }),
  getDaily: period => dispatch(getDailyPeriodData(period)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tools);
