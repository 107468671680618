import React from 'react';
import numeral from 'numeral';

const getRows = data => data.map((platform, index) => (
  <tr key={index}>
    <td>{platform.platform}</td>
    <td>{numeral(platform.impressions).format('0,0')}</td>
  </tr>
));

export default ({ data, loading }) => {
  let emptyText = loading ? 'Loading...': 'No data';

  if (data.length === 0) {
    return <div className='nodata'>{emptyText}</div>
  }
  data = data.slice(0, 8);

  return (
    <table className='table'>
      <thead>
        <tr>
          <th>ID</th>
          <th>Impressions</th>
        </tr>
      </thead>
      <tbody>{getRows(data)}</tbody>
    </table>
  );
}
