import React from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { CURRENT_M, getDailyPeriodData, L30DAYS, LAST_WEEK } from '../../reducer';
import css from './performance.module.scss';

const customStyles = {
  control: provided => ({
    ...provided, // display: 'none',
  }),
  menu: provided => ({
    ...provided,
    marginTop: 0,
    width: 150,
    left: 0,
    top: 30,
    color: 'red',
    background: '#ffffff',
    borderRadius: 5,
    boxShadow: '0 4px 16px rgba(126, 148, 180, 0.1)',
    zIndex: 3, //more than tab settings
  }),
  option: provided => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    padding: '8px 12px',
    cursor: 'pointer',
    backgroundColor: 'none',
    color: '#333',

    ':hover': {
      color: '#1B1B1B',
      backgroundColor: '#f5f5f5',
    },
    ':active': {
      backgroundColor: 'none',
      boxShadow: '0 4px 16px rgba(126, 148, 180, 0.10) inset',
    },
  }),
};

const ControlComponent = props => {
  const period = props.getValue()[0].label;

  return (
    <div
      {...props}
      tabIndex={0}
      onBlur={props.selectProps.onMenuClose}
      onClick={
        props.menuIsOpen ? props.selectProps.onMenuClose : props.selectProps.onMenuOpen
      }
      style={{ width: 50 }}
    >
      <p className={css.selector_label}>{period}</p>
    </div>
  );
};

const PeriodSelector = () => {
  const dispatch = useDispatch();
  const { performancePeriod } = useSelector(state => state);

  const setPeriod = value => {
    if (value !== performancePeriod) {
      dispatch(getDailyPeriodData(value));
    }
  };

  const options = [
    {
      label: LAST_WEEK,
      onClick: () => setPeriod(LAST_WEEK),
    },
    {
      label: CURRENT_M,
      onClick: () => setPeriod(CURRENT_M),
    },
    {
      label: L30DAYS,
      onClick: () => setPeriod(L30DAYS),
    },
  ];

  const value = options.find(o => o.label === performancePeriod);
  return (
    <div className={css.description}>
      <p className={css.label}>Summary for</p>
      <Select
        styles={customStyles}
        options={options}
        isSearchable={false}
        onChange={selectedOption => {
          selectedOption.onClick();
        }}
        components={{
          Control: ControlComponent,
        }}
        value={value}
      />
    </div>
  );
};

export default PeriodSelector;
