import React from 'react';
import numeral from 'numeral';
import { getShortNumber } from '../../helper';

const DailyPerformance = ({ data }) => {
  if (!data) {
    return <div className='nodata'>Loading...</div>;
  }
  if (!data.length) {
    return <div className='nodata'>No data</div>;
  }

  const rows = data.map((platform, index) => {
    return (
      <tr key={index}>
        <td>{platform.date}</td>
        <td>{numeral(platform.impressions).format('0,0')}</td>
        <td>${getShortNumber(platform.spend)}</td>
      </tr>
    );
  });

  return (
    <table className='dailyTable'>
      <thead>
        <tr>
          <th>Date</th>
          <th>Impressions</th>
          <th>Spend</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

export default DailyPerformance;
