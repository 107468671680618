export function getDate(date) {
    let dateObj = date;
    if (dateObj) {
        if (typeof dateObj !== 'object') {
            dateObj = new Date(date);
        }
        return dateObj.toISOString().slice(0, 10);
    } else {
        return null;
    }
}

export const getShortNumber = n => {
    if (!n || typeof n !== 'number') {
        return 0;
    }

    return n.toFixed(2).toString().replace('.00', '');
};
